.modal-dialog {
  max-width: 448px;
  margin: 0 auto;
  .close{
    opacity: 1;
    outline: 0;
    .icon-cancel-grey{
      font-size: 12px;
      &.black{
        &:before{
          color:$black;
        }
      }
    }
  }
  .modal-content{
    border-radius: 4px;
    border: 0;
  }
  .modal-header{
    border:0;
  }
  .modal-body{
    padding: 50px;
  }
  .cancel-btn{
    // color:$cancel-btn;
    padding: 0;
  }
  .modal-head{
    @include big-head();
    margin-bottom: 37px;
    span.sm-txt{
      font-size: $f-15;
      line-height: 24px;
      display: block;
      @include font-weight-style(400, normal);
    }
  }
  &.dialog-500{
    max-width: 500px;
    margin: 0 auto;
    @media only screen and (max-width: 568px) {
      max-width: 100%;
    }
  }
}

/* ==========================================================================
   ANT modal
 ========================================================================== */
 .ant-modal-mask{
  background-color: rgba(#000, 0.4);
 }

 .ant-modal{
  .modal-head{
    @include big-head();
    margin-bottom: 37px;
    span.sm-txt{
      font-size: $f-15;
      line-height: 24px;
      display: block;
      @include font-weight-style(400, normal);
      .bold{
        color:#212326;
        @include font-weight-style(800, normal);
      }
    }
  }
  .ant-modal-content{
   border-radius: 6px;
   border: 0;
   .ant-modal-close-x{
     .ant-modal-close-icon{
       &::after{
         content: '';
         display: block;
         background: url(../Assets/images/close.png);
         width: 20px;
         height: 20px;
       }
       svg{
         display: none
       }
     }
   }
   .ant-modal-body{
     padding: 50px;
   }
  }
}


 .w-100{
   width: 100%;
 }
 .modal-ft-btn {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  .ant-btn + .ant-btn{
    margin-left: 20px;
  }
}
.modal-ft-center-btn {
  display: flex;
  justify-content: center;
  .ant-btn + .ant-btn{
    margin-left: 20px;
  }
}

/* ==========================================================================
   common modal
 ========================================================================== */
.common-modal{
  &.wid450{
    width: 450px !important;
  }
  &.wid500{
    width: 500px !important;
  }
  .ant-modal-header{
    border-bottom:0;
    border-radius: 6px 6px 0 0;
    .ant-modal-title{
      display: none;
    }
  }
  .ant-modal-body{
    padding: 20px 50px 50px 50px !important;
  }
}

/* ==========================================================================
   forgot password
 ========================================================================== */
.forgot-password-modal{
  .ant-modal-header{
    border-bottom:0;
    border-radius: 6px 6px 0 0;
    .ant-modal-title{
      display: none;
    }
  }
  .ant-modal-body{
    padding: 20px 50px 50px 50px !important;
    .modal-ft-btn{
      width: 100% !important;
      .ant-form-item{
        width: 100% !important;
        margin-bottom: 0;
      }
    }
  }
}

/* ==========================================================================
   email sent
 ========================================================================== */
.emailsent-modal{
  .ant-modal-header{
    border-bottom:0;
    border-radius: 6px 6px 0 0;
    .ant-modal-title{
      display: none;
    }
  }
  .ant-modal-body{
    padding: 20px 50px 50px 50px !important;
    .email-send-box{
      text-align: center;
      margin: 0 auto;
      .big-head{
        margin: 40px 0 35px 0;
        span{
          line-height: 24px;
        }
      }
      .btn{
        width: 100%;
      }
    }
  }
}

/* ==========================================================================
   invite case modal
 ========================================================================== */
.invite-case-modal{
  .ant-modal-header{
    border-bottom:0;
    .ant-modal-title{
      display: none;
    }
  }
  .ant-modal-body{
    padding: 20px 50px 50px 50px !important;
  }
}

/* ==========================================================================
   create case modal
 ========================================================================== */
.create-case-modal{
  .ant-modal-header{
    border-bottom:0;
    .ant-modal-title{
      display: none;
    }
  }
  .ant-modal-body{
    padding: 20px 50px 50px 50px !important;
  }
}

.logout-wrapper{
  .big-head{
    margin: 40px 0 35px 0;
  }
}

/* ==========================================================================
   Case manager history
 ========================================================================== */
 .case-manager-history{
   .ant-modal-content{
    border-radius: 6px;
   }
  .profile-pic{
    @include profile-pic();
  }
  .user-detail{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .user-name{
      color:$primary-color;
      font-size: 13px;
      @include font-weight-style(400, normal);
      width: calc(100% - 150px);
      padding-right: 5px;
      @include truncate();
      span{
        color:$grey;
        display: block;
      }
    }
    .date{
      width: 150px;
      color:$primary-color;
      font-size: 12px;
      @include font-weight-style(400, normal);
    }
  }
 }