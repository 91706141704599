.btn {
  transition: background-color .5s ease;
  box-shadow:none;
  &:focus {
    outline: 0;
    box-shadow: none !important;
  }
  &.disabled,
  &:disabled {
    opacity: .65;
    pointer-events: none;
  }
  .btn-disable {
    opacity: 0.5;
    pointer-events: none;
  }
}


.disable-input {
  .form-control {
    color: #343e4a70 !important;
    background: rgba($form-placeholder, .1) !important;
    pointer-events: none;
  }
}

.primary-btn,
.ant-btn-primary {
  @include round();
  height: 52px;
  min-width: 160px;
  font-size: $f-16;
  border: 0;
  cursor: pointer;
  color: $base-color;
  text-transform: uppercase;
  background-color: $primary-color;
  @include font-weight-style(700, normal);
  @include padding(14px, 10px, 14px, 10px);
  outline: 0;
  &:hover,
  &:focus,
  &:active {
    color: $base-color;
    background-color: $primary-color-hover;
  }
}

.primary-btn-sm {
  @extend .primary-btn;
  height: 40px;
  font-size: 12px;
  padding: 5px;
  &.ht30{
    height: 30px;
  }
}

.grey-btn {
  @include round();
  height: 52px;
  min-width: 160px;
  font-size: $f-16;
  border: 0;
  cursor: pointer;
  color: $primary-color;
  text-transform: uppercase;
  background-color: $grey-05;
  @include font-weight-style(700, normal);
  @include padding(14px, 10px, 14px, 10px);
  outline: 0;
  &:hover,
  &:focus,
  &:active {
    color: $primary-color;
    background-color: $grey-light;
  }
  &.round4{
    border-radius: 4px !important;
    padding: 0 14px !important;
  }
}

.grey-sm-btn{
  @extend .grey-btn;
  height: 40px;
  font-size: 12px;
  padding: 5px;
}


.secondary-btn {
  @include round();
  height: 30px;
  min-width: 120px;
  font-size: $f-11;
  border: 0;
  cursor: pointer;
  color: $primary-color;
  text-transform: uppercase;
  background-color: $grey-light;
  @include font-weight-style(700, normal);
  @include padding(5px, 5px, 5px, 5px);
  outline: 0;
  &:hover,
  &:focus,
  &:active {
    color: $primary-color;
    background-color: $grey-light;
  }
}

.link-btn {
  color: $black;
  font-size: $f-16;
  background: transparent;
  @include font-weight-style(400, normal);
  cursor: pointer;
}

.link-btn-0 {
  padding: 0;
  &:hover {
    color: $base-color;
  }
  @extend .link-btn;
}

.red-btn {
  @include round();
  height: 52px;
  min-width: 245px;
  font-size: $f-16;
  border: 0;
  cursor: pointer;
  color: $white;
  text-transform: uppercase;
  background-color: $red-dark;
  @include font-weight-style(700, normal);
  @include padding(14px, 10px, 14px, 10px);
  outline: 0;
  &:hover,
  &:focus,
  &:active {
    color: $white;
    background-color: $red-dark;
  }
}

.blue-btn {
  @include round();
  height: 52px;
  min-width: 245px;
  font-size: $f-16;
  border: 0;
  cursor: pointer;
  color: $white;
  text-transform: uppercase;
  background-color: $blue-09;
  @include font-weight-style(700, normal);
  @include padding(14px, 10px, 14px, 10px);
  outline: 0;
  &:hover,
  &:focus,
  &:active {
    color: $white;
    background-color: $blue-06;
  }
}


.gold-btn {
  @include round();
  height: 52px;
  min-width: 245px;
  font-size: $f-16;
  border: 0;
  cursor: pointer;
  color: $primary-color;
  text-transform: uppercase;
  background-color: $gold-04;
  @include font-weight-style(700, normal);
  @include padding(14px, 10px, 14px, 10px);
  outline: 0;
  &:hover,
  &:focus,
  &:active {
    color: $primary-color;
    background-color: $gold-04;
  }
}


.link-btn-green {
  color: #1ed79e;
  font-size: $f-12;
  background: transparent;
  @include font-weight-style(400, normal);
  cursor: pointer;
  padding: 0;
  &:hover{
    color: #1ed79e;
  }
}

.link-btn-red {
  color: $red-light;
  font-size: $f-13;
  background: transparent;
  @include font-weight-style(900, normal);
  cursor: pointer;
  padding: 0;
  &:hover{
    text-decoration: underline;
  }
}

//-------button footer

.button-panel {
  @include wid100();
  text-align: right;
  .btn+.btn {
    margin-left: 10px;
  }
}

.center-btn {
  margin: 0 auto;
  text-align: center;
  .btn+.btn {
    margin-left: 10px;
  }
}

.btn-space {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.full-btn {
  width: 100%;
  .btn {
    width: 100%;
  }
}
.all-user-btn{
  color: #5e6267;
  font-size: $f-13;
  min-width: 130px;
  text-transform: none;
  height: 40px;
  padding: 10px;
  @include font-weight-style(500, normal);
  position: relative;
  .anticon-down{
    svg{
      display: none;
    }
    &:after{
      content: '';
      display: block;
      position: absolute;
      right: 10px;
      top: 10px;
      bottom: 0;
      background: url(../Assets/images/grey-caret.png);
      width: 15px;
      height: 15px;
    }
  }
}

.invite-btn{
  font-size: 12px;
  width: 210px;
  height: 40px;
  padding: 5px;
}
.edit-profile{
  width: 150px;
  font-size: 12px;
  height: 40px;
  padding: 5px;
  :disabled{
    color: #ffffff;
  }
}
.case-cancel-btn{
  width: 160px;
  padding: 15px;
  margin-right: 20px;
  display: inline-block;
  text-align: center;
  line-height: normal;
}
.change-status{
  min-width: 189px !important;
  font-size: 13px;
  height: 40px;
  padding: 13px 43px;
  color: #ffffff !important;
  position: relative;
  :disabled{
    color: #ffffff;
  }
  a[disabled]{
    color: #ffffff !important;
  }
  &::after{
    content: '';
    right: 25px;
    top: 17px;
    width: 0;
    height: 0;
    position: absolute;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #fff;
  }
}