// main background //

$main-background:#f8f8f8;
$base-color: #ffffff;
$white: #ffffff;
$primary-color: #212326;
$primary-color-hover:#212326;

$black-head:#212326;

$grey:#5e6267;
$grey-light:#eeefef;
$grey-01: #111;
$grey-02: #222;
$grey-03: #333;
$grey-04: #444;
$grey-05: #d9d9d9;

$gold-04: #ffd666;

$blue-06: #1890ff;
$blue-09: #003a8c;

$form-placeholder:#212326;
$form-border-color:#d7d7d7;
$form-back-color:#fff;
$form-label: #5e6267;

$red-dark:#ef2c2c;
$green: #1ed79e;

// not in use
$default-color: #212326;
$black:#000000;
$black-dark:#070707;
$black-sm:#1b1a1a;
$radio-btn-border:#ababab;
$drop-hover:#8e8e8e;
$border: #f0edf7;


$red-light:#f15454;


$dark-grey: #252525;
$peach: #c49e4b;




//font size
$f-10: 10px;
$f-11: 11px;
$f-12: 12px;
$f-13: 13px;
$f-14: 14px;
$f-15: 15px;
$f-16: 16px;
$f-18: 18px;
$f-19: 19px;
$f-20: 20px;
$f-23: 23px;
$f-24: 24px;
$f-25: 25px;
$f-29: 29px;
$f-30: 30px;
$f-34: 34px;
$f-35: 35px;
$f-36: 36px;
$f-38: 38px;
$f-40: 40px;
$f-45: 45px;

// img path
$img-path: '~assets/images/';
