@import '../Scss/styles.scss';

.badge{
    font-size: $f-11;
    color: $white;
    width: fit-content;
    text-align: center;
    padding: 4px 10px;
    margin: 2px 2px;
    border-radius: 10px;
    background: #3f5bbb;
    border: 0;
    &.red-dark{
      background: $red-dark;
    }
    &.green{
      background: $green;
    }
  }