/* ==========================================================================
   signup
  ========================================================================== */
  .signup-wrapper{
    padding: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    .container-fluid{
      display: flex;
      height: 100%;
      padding: 0;
      .cols{
        width: calc(100% / 2);
        @media only screen and (max-width: 992px) {
          width: 100%;
        }
      }
      .signup-left-item{
        padding: 60px 50px 20px 50px;
        display: flex;
        align-items: center;
        .ant-row{
          width: 100%;
          .ant-form{
            width: 100%;
          }
        }
        .big-head{
          margin: 60px 0 57px 0;
        }
        .signup-footer{
          .floating-input{
            padding-bottom: 0;
          }
          .ant-form-item{
            margin-bottom: 0;
          }
          .primary-btn{
            min-width: 100%;
            margin-top: 50px;
          }
        }
        .forgot-div{
          display: flex;
          align-items: flex-end;
        }
        .link-btn{
          color:$grey;
          padding: 0;
          font-size: 14px;
          margin-left: auto;
          display: flex;
          position: relative;
        }
      }
      .signup-right-item{
        background: url(../Assets/images/login-back.png) no-repeat center center;
        background-size: cover;
        width: 100%;
      }
    }
  }
  .err-msg{
    float: left !important;
  }
