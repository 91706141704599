/* ==========================================================================
   Form
  ========================================================================== */
  .floating-input {
    @include wid100();
    padding-bottom: 10px;
  }

  .form-group {
    position: relative;
    margin-bottom: 4px;
    @include wid100();
    label{
      margin-bottom: 8px;
      color: $form-label;
      line-height: normal;
      font-size: $f-13;
      @include font-weight-style(400, normal);
      position: absolute;
      top: -6px;
      z-index: 2;
      padding: 0 5px;
      background: white;
      left: 10px;
      &.mb-0{
        margin-bottom: 0;
      }
    }
    .form-control,
    .ant-select {
      .ant-select-selector {
        .ant-select-selection-search{
          position: relative;  
          .ant-select-selection-search-input{
            position: relative;
          }
        }                
      }
    }
    .ant-input {
      width: 100%;
      height: 52px;
      border-radius: 4px;
      padding: 17px 15px;
      font-size: $f-14;
      color: $black-head;
      background-color: $form-back-color;
      position: relative;
      @include font-weight-style(400, normal);
      border-color: $form-border-color;
      -webkit-appearance: none;
      &:focus {
        box-shadow: none !important;
      }
      &.password{
        padding-right: 50px;
      }
    }
    .error-active {
      .form-control,
      .ant-input {
        z-index: 0;
        border: 1px solid $red-dark !important;
      }
    }
    .error {
      margin-top: -25px;
      color: $red-light;
      font-size: $f-13;
      @include font-weight-style(500, normal);
    }
    @include placeholder{
      font-size: $f-14;
      color:$form-placeholder;
        @include font-weight-style(500, normal);
    }
    .form-control:disabled,
    .form-control[readonly]{
      color: $black-dark !important;
      -webkit-text-fill-color: $black-dark !important;
    }
    .form-control:disabled::-webkit-input-placeholder,
    .form-control[readonly]::placeholder
    {
      color: rgba($black-dark, 0.2);
      -webkit-text-fill-color:rgba($black-dark, 0.2);
    }
    .forgot-txt{
      font-size: 11px;
      right: 15px;
      top: 17px;
      z-index: 1;
      padding: 0;
      cursor: pointer;
      color:$black-dark;
      position: absolute;
      background-color: transparent;
      box-shadow: none;
      @include font-weight-style(500, normal);
    }
    .hint {
      float: right;
      font-size: 12px;
      line-height: 13px;
      color: $primary-color;
      width: 100%;
      text-align: right;
      position: absolute;
      bottom: -1px;
      right: 0;
      @include font-weight-style(700, normal);
    }
  }

  .ant-form-item-explain.ant-form-item-explain-error {
    color: $red-light;
    font-size: $f-12;
    @include font-weight-style(500, normal);
  }

  .eye-icon{
    position: relative;
      img{
        position: absolute;
        right: 15px;
        top: 15px;
        cursor: pointer;
      }
  }

  .custom-error{
    position: absolute;
    bottom: 17px;
  }

  /* ==========================================================================
     other error handling
    ========================================================================== */
    .error {
      float: left;
      color: $red-light;
      font-size: $f-13;
      @include font-weight-style(500, normal);
      @media only screen and (max-width: 767px) {
        font-size: $f-12;
      }
    }

    .check-error{
      display: block;
      width: 100%;
    }

    .disable-input{
      .floating-input{
        .form-control{
          color: rgba($black-dark, 0.3) !important;
          -webkit-text-fill-color:rgba($black-dark, 0.3) !important;
        }
        img{
          pointer-events: none;
          opacity: 0.6;
        }
        span.icon-close-black{
          pointer-events: none;
          opacity: 0.2;
        }
        .input-group-text{
          color: rgba($black-dark, 0.3) !important;
          -webkit-text-fill-color:rgba($black-dark, 0.3) !important;
        }
      }
    }

    .ant-select{
      &.from-select{
        width: 100%;
        height: 52px;
        border-radius: 4px;
        font-size: $f-14;
        color: $black-head;
        background-color: $form-back-color;
        position: relative;
        @include font-weight-style(400, normal);
        border-color: $form-border-color;
        -webkit-appearance: none;
      }
    }
    .ant-select-selection-search-input{
      background-color: rgba($black-dark, 0.2);
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector{
      border-radius: 4px;
      border: 1px solid $form-border-color;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
      height: 52px;
      padding: 10px 15px;
    }
    .ant-select-arrow{
      width: 0;
      height: 0;
      font-size: 0;
      &::after{
        content: '';
        display: block;
        background: url(../Assets/images/grey-caret.png);
        width: 20px;
        height: 20px;
        position: absolute;
        right: 0;
        top: -5px;
      }
    }
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
      box-shadow: none;
      border-color:$form-border-color;
    }
    .ant-select:not(.ant-select-disabled):hover .ant-select-selector{
      border-color:$form-border-color;
    }

    .custom-input-forgot-pw{
    width: 100%;
    height: 52px;
    border-radius: 4px;
    padding: 17px 15px;
    font-size: 14px;
    color: #212326;
    background-color: #fff;
    position: relative;
    font-family: 'Archivo', sans-serif;
    font-weight: 400;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border-color: #d7d7d7;
    -webkit-appearance: none;
    }
    .case-edit-quill{
      #Description{
        .ql-snow{
          .ql-editor{
            max-height: 156px;
            min-height: 156px;
          }
        }
      }
    }
    .life-map-input::placeholder{
      color: rgb(201, 196, 196);
    }