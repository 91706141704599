table {
  border-collapse:collapse;
  border-spacing:0;
}

.table-listing {
  color: $black-head;
  margin-top: 20px;
  .table-body-wrapper,
  .table-head-wrapper {
    display: flex;
    flex-direction: column;
    &.empty-wrapper{
      align-items: center;
      text-align: center;
      border-top: 1px solid rgba($black-dark, 0.2);
      @media only screen and (max-width: 992px) {
        border:0;
      }
      width: 100%;
      .table-body{
        background-color: $base-color;
        height: 320px;
        @include flex-center();
        width: 100%;
        @media only screen and (max-width: 640px) {
          height: 310px;
        }
        .no-record{
          text-align: center;
        }
      }
    }
  }
  .table-head,
  .table-body {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0;
    min-height: 78px;
    @media only screen and (max-width: 1024px) {
      padding: 15px 0;
    }
  }
  .table-head {
    min-height: auto;
    background-color: rgba(#5e6267, 0.04);
    .thead-data{
      flex-grow: 1;
      font-size: $f-11;
      color:$primary-color;
      padding: 15px 10px 15px 10px;
      word-wrap: break-word;
      text-transform: uppercase;
      @include font-weight-style(600, normal);
    }
  }
  .table-body {
    position: relative;
    background: $white;
    .thead-data{
      flex-grow: 1;
      color:$grey;
      font-size: 13px;
      padding: 10px 10px 10px 10px;
      word-wrap: break-word;
      @include font-weight-style(400, normal);
    }
  }
  .cols-6{
    .thead-data{
      width: calc(100% / 6);
    }
  }
  .cols-7{
    .thead-data{
      width: calc(100% / 7);
    }
  }
}

.strip-table{
  position: relative;
  .table-head {
    margin-bottom: 15px;
  }
  .table-body{
    & + .table-body{
      margin-top: 15px;
    }
  }
}


.tb-profile{
  .profile-pic{
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  .profile-content{
    .user-name{
      color:#212326;
      font-size: $f-13;
      @include font-weight-style(500, normal);
    }
    .email{
      display: block;
      color: #5e6267;
      font-size: 12px;
      @include font-weight-style(400, normal);
    }
  }
  .user-count{
    font-size: 10px;
    color: #212326;
    min-width: 20px;
    height: 20px;
    padding: 3px 3px 4px 4px;
    cursor: pointer;
    border-radius: 4px;
    background-color: rgba(#5e6267, 0.2);
    @include font-weight-style(700, normal);
  }
}

.menu-dot-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tb-bold-f2{
  @include font-weight-style-2(700, normal);
}
.tb-bold{
  @include font-weight-style(600, normal);
}


/* ==========================================================================
   ANT pagination
  ========================================================================== */
  .ant-pagination-prev,
  .ant-pagination-next{
    width: 50px;
    border-radius: 2px;
    background-color: rgba(#5e6267, 0.05);
    border: 1px solid transparent;
    &:hover{
      border: solid 1px #212326;
    }
    a{
      font-size: 11px;
      color: #5e6267 !important;
      @include font-weight-style(500, normal);
    }
  }
  .ant-pagination-item{
    border-radius: 2px;
    background-color: rgba(#5e6267, 0.05);
    border: 1px solid transparent;
    a{
      font-size: 11px;
      color: #5e6267 !important;
    }
    &:hover{
      border: solid 1px #212326;
    }
  }
  .ant-pagination-item-active{
    border-color: transparent;
    background-image: linear-gradient(45deg, #212326, #131416);
    a{
      color: $white !important;
    }
  }

/* ==========================================================================
   ANT table
  ========================================================================== */
  .ant-table-thead{
  tr{
      th{
        font-size: $f-11;
        color:$primary-color;
        padding: 15px 10px 15px 10px;
        word-wrap: break-word;
        text-transform: uppercase;
        @include font-weight-style(600, normal);
        background: rgba(#5e6267, 0.06);
        border-bottom:20px solid #f8f8f8;
        &:hover{
          background: rgba(#5e6267, 0.06);
        }
      }
    }
  }

  .ant-table-tbody{
    tr{
      &.ant-table-row{
        &:hover{
          td{
            background: $white !important;
          }
        }
      }
      td{
        color:$grey;
        font-size: 13px;
        word-wrap: break-word;
        @include font-weight-style(400, normal);
        border-bottom:20px solid #f8f8f8;
      }
    }
  }

  .ant-table-thead th.ant-table-column-has-sorters:hover {
    background: rgba(#5e6267, 0.06);
  }

  .ant-table-column-sorters{
    display: inline-flex;
    justify-content:flex-start;
    .ant-table-column-sorter-inner{
      span{
        display: none;
      }
      &::after{
        content: '';
        display: block;
        background: url(../Assets/images/shorting-arrow.png);
        width: 20px;
        height: 20px;
        margin-left: 2px;
      }
    }
  }
  td.ant-table-column-sort{
    background: $white;
  }
  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{
    display: none;
  }

  .table-no-gap{
    .ant-table-thead{
      tr{
          th{
            border-bottom:0;
          }
        }
    }
    .ant-table-tbody{
      tr{
        td{
          border-bottom:0;
        }
      }
    }
  }
  .ant-layout-footer{
    display: none;
  }

  .ant-table-placeholder{
    border-radius: 4px;
    .ant-empty-normal{
      min-height: 350px;
      margin: 0;
      padding: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    svg{
      display: none;
    }
    .ant-empty-image{
      width: 75px;
      height: 75px;
      margin: 0 auto 35px;
      padding: 20px;
      border-radius: 4px;
      background-color: rgba(#5e6267, 0.04);
      &::after{
        content: '';
        display: block;
        background: url(../Assets/images/table-search.png) no-repeat center center;
        background-size: 100%;
        width: 35px;
        height: 35px;
        z-index: 2;
      }
    }
    .ant-empty-description{
      color: #212326;
      font-size: 20px;
      @include font-weight-style(900, normal);
      span{
        margin: 10px 0 0;
        font-size: 14px;
        text-align: center;
        color: #5e6267;
        line-height: 24px;
        @include font-weight-style(400, normal);
        display: block;
      }
    }
  }

  .pagination-count-data{
    color: #212326;
    font-size: 13px;
    @include font-weight-style(500, normal);
    position: absolute;
    bottom: 20px;
  }

  .tma-search-box{
    .ant-btn{
      font-size: 12px;
    }
    .tma-table{
      th.ant-table-cell{
        border-bottom: 0;
      }

      tr.ant-table-placeholder{
        td{
          &:last-child{
            border-bottom: 0;
          }
        }
      }
      tr.ant-table-row{
        &:last-child{
          td{
            border-bottom: 0;
          }
        }
      }

      thead{
        tr{
          th{
            &:first-child{
              width: 15%;
            }
            &:nth-child(2){
              width: 30%;
            }
            &:nth-child(3){
              width: 40%;
            }
            &:nth-child(4){
              width: 15%;
              text-align: center;
            }
          }
        }
      }
      tbody{
        tr{
          td{
            &:first-child{
              width: 15%;
            }
            &:nth-child(2){
              width: 30%;
            }
            &:nth-child(3){
              width: 45%;
            }
            &:nth-child(4){
              width: 10%;
              text-align: center;
            }
          }
        }
      }
    }


  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header{
    font-size: 12px;
  }

/* ==========================================================================
   recent-user-table
  ========================================================================== */
  .recent-user-table{
    .table-head,
    .table-body{
      .thead-data{
        &:first-child{
          width: 25%;
        }
        &:nth-child(2){
          width: 20%;
        }
        &:nth-child(3){
          width: 15%;
        }
        &:nth-child(4){
          width: 20%;
          text-align: center;
        }
        &:nth-child(5){
          width: 10%;
          text-align: center;
        }
        &:nth-child(6){
          width: 10%;
          text-align: center;
        }
      }
    }
  }

  /* ==========================================================================
   recent-case-table
  ========================================================================== */
  .recent-case-table{
    .table-head,
    .table-body{
      .thead-data{
        &:first-child{
          width: 25%;
        }
        &:nth-child(2){
          width: 20%;
        }
        &:nth-child(3){
          width: 25%;
        }
        &:nth-child(4){
          width: 20%;
          text-align: center;
        }
        &:nth-child(5){
          width: 10%;
          text-align: center;
        }
      }
    }
  }

  .c-show{
    display: block;
  }
  .c-hide{
    display: none;
  }