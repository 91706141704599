.main-dashboard{
  height: 100vh;
  background-color: $main-background;
  .downlaod-btn-wrapper {
    .download-btn {
      background: transparent !important;
      box-shadow: none !important;
      color: $black-sm !important;
      font-size: 13px;
      border: 1px solid $black-sm;
      border-radius: 4px;
      padding: 5px 14px;
      text-align: center;
      min-width: 137px;
      @include font-weight-style(600, normal);
      .icon-download-sm {
        margin-right: 5px;
      }
      .download-none{
        @media only screen and (max-width: 1024px) {
          display: none;
        }
      }
    }
  }//downlaod-btn-wrapper

  .search-broker {
    margin-left: 22px;
    @media only screen and (max-width: 1024px) {
      margin-left: 15px;
    }
    .input-container {
      display: flex;
      align-items: center;
      width: 100%;
      @media only screen and (max-width: 767px) {
        flex-direction: row-reverse;
      }
    }
    .icon-search {
      padding: 10px;
      font-size: 20px;
      @media only screen and (max-width: 1200px) {
        padding: 5px;
      }
    }
    input {
      width: 100%;
      padding: 2px;
      outline: none;
      border: 0;
      background: transparent;
      font-size: $f-12;
      @include font-weight-style(500, normal);
      @media only screen and (max-width: 767px) {
        text-align: right;
      }
    }
    @include placeholder{
      color:$form-placeholder;
      @media only screen and (max-width: 767px) {
        color:transparent;
      }
    }
  } //search-broker

  .company-logo {
    width: 36px;
    height: 36px;
    margin: 0 24px;
    border-radius: 50%;
    overflow: hidden;
    padding: 8px;
    background-color: $base-color;
    @media only screen and (max-width: 1200px) {
      margin: 0 15px;
    }
    img {
      width: 100%;
      height: 100%;
    }
  } //company-logo


  .left-navbar{
    background-image: linear-gradient(43deg, #212326, #131416);
    width: 280px;
    position: fixed;
    height: 100%;
    padding: 0;
    .logo-header{
      display: flex;
      justify-content: space-between;
      padding: 50px 40px;
      .logo-icon {
        img{
          width: 100%;
        }

      }
    }

    .main-list{
      margin: 0;
      padding: 0;
      padding-inline-start: 0;
     li.item-link{
        position: relative;
        &:active, &:focus{
          background-color:transparent;
          .link{
            color:#ffffff;
            outline: 0;
            @include font-weight-style(400, normal);
            span{
              text-transform: none;
            }
          }
        }
        &:hover{
          background-color:transparent;
          .link{
            color:#ffffff;
            outline: 0;
            @include font-weight-style(400, normal);
            span{
              text-transform: none;
            }
          }
          .dash-icon{
            background: url(../Assets/images/sidebar/dashboard-active.svg) no-repeat;
            width: 21px;
            height: 18px;
            display: block;
            background-size: 17px;
            margin-right: 15px;
          }
          .user-icon{
            background: url(../Assets/images/sidebar/users-active.svg) no-repeat;
            width: 21px;
            height: 20px;
            display: block;
            background-size: 19px;
            margin-right: 15px;
          }
          .case-icon{
            background: url(../Assets/images/sidebar/case-active.svg) no-repeat;
            width: 21px;
            height: 20px;
            display: block;
            background-size: 20px;
            margin-right: 15px;
          }
          .password-icon{
            background: url(../Assets/images/sidebar/password-active.svg) no-repeat;
            width: 21px;
            height: 22px;
            display: block;
            background-size: 17px;
            margin-right: 15px;
          }
          .logout-icon{
            background: url(../Assets/images/sidebar/sign-out-active.svg) no-repeat;
            width: 21px;
            height: 21px;
            display: block;
            background-size: 100%;
            margin-right: 15px;
          }
        }
        &.active{
          .link{
            color:#ffffff;
            border-left: 2px solid #ffc000;
            background-image: linear-gradient(to right, rgba(#fff, 0.05), rgba(255, 255, 255, 0));
          }
        }
        .link {
          width: 100%;
          position: relative;
          padding: 32px 25px 32px 25px;
          cursor: pointer;
          text-decoration: none;
          font-size: 15px;
          color: #b9b9b9;
          @include font-weight-style(400, normal);
          display: flex;
          align-items: center;
          border: 0;
          background: transparent;
          text-align: left;
          border-left: 2px solid transparent;
          &:focus{
            outline: 0;
          }
          img + img{
            margin-left: 10px;
          }
          .l10{
            margin-left: 10px;
          }
        }
        .white-btn{
          margin-top: 20px;
          padding: 5px 12px;
          font-size: 12px;
          text-transform: none;
          @include font-weight-style(400, normal);
        }
        .logout-btn{
          margin-top: 35px;
          text-align: left;
          padding:0;
          text-transform: capitalize;
          font-size: 15px;
          img{
            left: 7px;
            position: relative;
            top: 2px;
          }
        }
      }

      .dash-icon{
        background: url(../Assets/images/sidebar/dashboard.svg) no-repeat;
        width: 21px;
        height: 18px;
        display: block;
        background-size: 17px;
        margin-right: 15px;
      }
      .user-icon{
        background: url(../Assets/images/sidebar/users.svg) no-repeat;
        width: 21px;
        height: 20px;
        display: block;
        background-size: 19px;
        margin-right: 15px;
      }
      .case-icon{
        background: url(../Assets/images/sidebar/case.svg) no-repeat;
        width: 21px;
        height: 20px;
        display: block;
        background-size: 20px;
        margin-right: 15px;
      }
      .password-icon{
        background: url(../Assets/images/sidebar/password.svg) no-repeat;
        width: 21px;
        height: 22px;
        display: block;
        background-size: 17px;
        margin-right: 15px;
      }
      .logout-icon{
        background: url(../Assets/images/sidebar/sign-out.svg) no-repeat;
        width: 21px;
        height: 21px;
        display: block;
        background-size: 100%;
        margin-right: 15px;
      }
      li.item-link.active{
        .dash-icon{
          background: url(../Assets/images/sidebar/dashboard-active.svg) no-repeat;
          width: 21px;
          height: 18px;
          display: block;
          background-size: 17px;
          margin-right: 15px;
        }
        .user-icon{
          background: url(../Assets/images/sidebar/users-active.svg) no-repeat;
          width: 21px;
          height: 20px;
          display: block;
          background-size: 19px;
          margin-right: 15px;
        }
        .case-icon{
          background: url(../Assets/images/sidebar/case-active.svg) no-repeat;
          width: 21px;
          height: 20px;
          display: block;
          background-size: 20px;
          margin-right: 15px;
        }
        .password-icon{
          background: url(../Assets/images/sidebar/password-active.svg) no-repeat;
          width: 21px;
          height: 22px;
          display: block;
          background-size: 17px;
          margin-right: 15px;
        }
        .logout-icon{
          background: url(../Assets/images/sidebar/sign-out-active.svg) no-repeat;
          width: 21px;
          height: 21px;
          display: block;
          background-size: 100%;
          margin-right: 15px;
        }
      }
    }

    .admin-tag{
      width: 240px;
      height: 53px;
      margin: 22px 20px 0;
      padding: 13px 60px 12px 15px;
      border-radius: 4px;
      background-color: rgba(#5e6267, 0.05);
      display: flex;
      align-items: center;
      img{
        align-self: center;
      }
      .admin-text{
        color: #b9b9b9;
        font-size: 12px;
        padding-left: 15px;
        @include font-weight-style(900, normal);
        span{
          display: block;
          color: #b9b9b9;
          font-size: 11px;
          @include font-weight-style(400, normal);
        }
      }
    }
  } //left-navbar



  .full-width{
    width: calc(100% - 280px);
    margin-left: 280px;
    padding: 50px 50px 80px 50px;
    background-color: $main-background;
    min-height: 100%;
    position: relative;
  }//full-width
}

.bottom-footer{
  padding: 22px 15px;
  text-align: center;
  color: #5e6267;
  font-size: 15px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: $main-background;
  width: 100%;
  border-top: 1px solid rgba(#5e6267, 0.05);
}

.dashboard-empty{
  @extend .empty-box;
  padding: 0;
  min-height: calc(100vh - 130px);
  background-color: transparent;
  @media only screen and (max-width: 767px) {
    min-height: 150px;
  }
}

.dashboard-header-text{
  font-size: 20px;
  color: #212326;
  @include font-weight-style(400, normal);
  span.bold{
    @include font-weight-style(900, normal);
  }
  span.sm-txt{
    font-size: 13px;
    color: #5e6267;
    display: block;
  }
}
.dashboard-card-wrapper{
  display: flex;
  margin:50px 0 60px 0;
  .box{
    width: 33.33%;
    max-width: 285px;
    height: 120px;
    margin: 1px 23px 0 0;
    padding: 30px;
    border-radius: 4px;
    color: $white;
    @include font-weight-style(600, normal);
    p{
      text-transform: uppercase;
      margin-bottom: 0;
      color:$white;
    }
    .count{
      font-size: 35px;
      @include font-weight-style(800, normal);
    }
    &.grey{
      background-image: linear-gradient(67deg, #212326, #131416);
    }
    &.org{
      background-color: #ff6923;
    }
    &.blue{
      background-color: #3f5bbb;
    }
  }
}

.white-box{
  padding: 24px 0;
  border-radius: 4px;
  background-color: $white;
}

.tb-active{
  font-size: 11px;
  color: #1ed79e;
  min-width: 62px;
  height: 20px;
  padding: 0 10px;
  border-radius: 10px;
  line-height: 20px !important;
  text-transform: uppercase;
  background-color: rgba(#1ed79e, 0.1);
  border-color:#1ed79e !important;
  @include font-weight-style(600, normal);
  &:hover,&:focus,&:active{
    color: #1ed79e !important;
    border-color:#1ed79e !important;
    background-color: rgba(#1ed79e, 0.1) !important;
  }
  &.big{
    height: 40px;
    min-width: 132px;
    border-radius: 20px;
    font-size: 13px;
    padding: 0 10px !important;
    line-height: 40px !important;
  }
}
.tb-open{
  font-size: 11px;
  color: #5e6267;
  width: 53px;
  height: 20px;
  line-height: 20px !important;
  padding: 0 10px;
  border-radius: 10px;
  text-transform: uppercase;
  background-color: rgba(#5e6267, 0.1);
  @include font-weight-style(600, normal);
  &:hover,&:focus,&:active{
    color: #5e6267 !important;
    background-color: rgba(#5e6267, 0.1) !important;
  }
}
.tb-complete{
  font-size: 11px;
  color: #3f5bbb;
  min-width: 71px;
  height: 20px;
  line-height: 20px !important;
  padding: 0 10px;
  border-radius: 10px;
  text-transform: uppercase;
  background-color: rgba(#3f5bbb, 0.1);
  @include font-weight-style(600, normal);
  &:hover,&:focus,&:active{
    color: #3f5bbb !important;
    background-color: rgba(#3f5bbb, 0.1) !important;
  }
}

.tb-inActive{
  font-size: 11px;
  color: $red-dark;
  min-width: 71px;
  height: 20px;
  padding: 0 10px;
  line-height: 20px !important;
  border-radius: 10px;
  text-transform: uppercase;
  background-color: rgba(#eeefef, 0.6);
  @include font-weight-style(600, normal);
  &:hover,&:focus,&:active{
    color: $red-dark !important;
    background-color: rgba(#eeefef, 0.6) !important;
  }
}

.tb-pending{
  font-size: 11px;
  color: #436ba7;
  width: 71px;
  height: 20px;
  padding: 0 10px;
  border-radius: 10px;
  text-transform: uppercase;
  background-color: rgba(#3f5bbb, 0.1);
  @include font-weight-style(600, normal);
  &:hover,&:focus,&:active{
    color: #3f5bbb !important;
    background-color: rgba(#3f5bbb, 0.1) !important;
  }
}

.header-wrapper{
  display: flex;
  padding: 0 20px 20px 20px;
  justify-content: space-between;
  &.pd0{
    padding: 0;
  }
  h3{
    @include big-head();
    text-transform: none;
    &.cap{
      text-transform: uppercase;
    }
  }
  .right-item{
    display: flex;
  }
}

.back-header-wrapper{
  display: flex;
  justify-content: space-between;
  h3{
    @include big-head();
    text-transform: uppercase;
    font-size: 16px;
    @include font-weight-style(700, normal);
  }
  .left-item{
    display: flex;
    align-items: center;
  }
  .back{
    @extend .back;
  }
}

.search-wrapper{
  width: 220px;
  border-radius: 4px;
  background-color: rgba(#5e6267, 0.04);
  position: relative;
  margin-right: 10px;
  height: 40px;
  &.wid300{
    width: 300px;
  }
  img{
    position: absolute;
    left: 15px;
    top: 9px;
  }
  input{
    width: 100%;
    background-color: rgba(#5e6267, 0.04);
    border: 0;
    height: 100%;
    padding-left: 40px;
    &:focus{
      background-color: rgba(#5e6267, 0.04);
      box-shadow: none;
      border-color: transparent;
    }
  }
}


/* ==========================================================================
   view profile
 ========================================================================== */
 .head-wrapper{
  width: 100%;
  padding: 0 0 30px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: dashed 1px rgba(#5e6267, 0.5);
  h3{
    @include big-head();
    font-size: 15px;
    margin-bottom: 0;
  }
  .btn{
    height: 30px;
    font-size: 11px;
  }
 }

 .view-profile-box{
   padding: 30px;
   width: 100%;
   .profile-wrapper{
    width: 100%;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
     .profile-pic{
       width: 60px;
       height: 60px;
     }
     .profile-content{
       margin-left: 20px;
       .user-name{
         font-size: 16px;
       }
       .email{
        color: #5e6267;
        font-size: 13px;
        margin-top: 5px;
        @include font-weight-style(400, normal);
       }
     }
     .btn-right{
       .grey-btn{
          min-width: 132px;
          height: 30px;
          padding: 9px 5px;
          font-size: 11px;
          border-radius: 20px;
          background-color: #eeefef;
          & + .grey-btn{
            margin-left: 10px;
          }
        }
     }
   }
   .step-2{
    width: 100%;
    border-top: dashed 1px rgba(#5e6267, 0.5);
    padding: 30px 0;
    display: flex;
    &.border0{
      border-top: 0;
    }
    .detail{
      display: flex;
      flex-grow: 1;
      flex-direction: column;
    }
    .show-more{
      width: 120px;
      min-width: auto;
      height: 30px;
      padding: 9px 5px;
      font-size: 11px;
      border-radius: 4px;
      background-color: #eeefef;
      line-height: normal;
    }
   }

  .ant-empty-normal{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px 0;
   }
   .ant-empty-image{
    &::after{
      content: '';
      display: block;
      background: url(../Assets/images/folder.svg) no-repeat center center;
      background-size: 100%;
      width: 35px;
      height: 35px;
      z-index: 2;
    }
  }
  .ant-empty-description{
    color: #212326;
    font-size: 14px;
    margin-top: 20px;
    @include font-weight-style(800, normal);
    span{
      margin: 10px 0 0;
      font-size: 14px;
      text-align: center;
      color: #5e6267;
      line-height: 24px;
      @include font-weight-style(400, normal);
      display: block;
    }
  }
 }

 .label-text{
  margin-bottom: 8px;
  font-size: 13px;
  color: #5e6267;
 }
 .label-vale{
  font-size: 15px;
  color: #212326;
 }

 .account-password{
   padding: 27px 30px;
   margin: 30px 0 50px 0;
   display: flex;
   justify-content: space-between;
   .left-item{
     display: flex;
     align-items: center;
   }
   h3{
     @include head();
     span{
       margin-top: 0;
     }
   }
   p{
    font-size: 15px;
    color: #212326;
    margin-bottom: 0;
  }
 }


 /* ==========================================================================
   edit profile
 ========================================================================== */
 .edit-profile-box{
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  .profile-wrapper{
   width: 100%;
   padding: 30px 30px 0 30px;
   margin-bottom: 37px;
   display: flex;
   justify-content: space-between;
   .user-profile-inline{
     width: 100%;
     display: flex;
   }
    .profile-pic{
      width: 90px;
      height: 90px;
    }
    .profile-content{
      margin-left: 20px;
      .upload-btn{
        @extend .input-type;
        position: relative;
        @extend .grey-btn;
        min-width: 140px;
        width: 140px;
        height: 40px;
        padding: 5px;
        font-size: 12px;
      }
      .help-text{
        color:#5e6267;
        font-size: $f-11;
        margin-top: 6px;
        @include font-weight-style(400, normal);
        font-style: italic;
      }
    }
  }
 }

 .edit-profile-form{
   padding: 30px;
  }
  .edit-profile-form-footer{
   .button-panel{
    background-color: $main-background;
    padding: 15px 0;
     button + button{
       margin-left: 20px;
     }
   }
 }

  /* ==========================================================================
   case detail
 ========================================================================== */
 .case-details-card{
  display: flex;
  .ant-col{
    display: flex;
     .box-scroll{
        min-height: 350px;
        width: 100%;
        .ant-list-items{
          overflow: auto;
          max-height: 369px;
        }
        .ant-empty-normal{
          min-height: 350px;
          margin: 0;
          padding: 15px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        svg{
          display: none;
        }
        .ant-empty-image{
          &::after{
            content: '';
            display: block;
            background: url(../Assets/images/folder.svg) no-repeat center center;
            background-size: 100%;
            width: 35px;
            height: 35px;
            z-index: 2;
          }
        }
        .ant-empty-description{
          color: #212326;
          font-size: 14px;
          margin-top: 20px;
          @include font-weight-style(800, normal);
          span{
            margin: 10px 0 0;
            font-size: 14px;
            text-align: center;
            color: #5e6267;
            line-height: 24px;
            @include font-weight-style(400, normal);
            display: block;
          }
        }
     }
   }
 }

 .small-box{
  padding: 20px 30px 0 30px;
  .header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: dashed 1px rgba(#5e6267, 0.5);
    h3{
      @include big-head();
      font-size: 15px;
      @include font-weight-style(800, normal);
    }
    .grey-sm-btn{
      height: 30px;
    }
  }
  .body{
    padding: 22px 0;
  }
}

.sm-box-list{
  ul.ant-list-item{
    padding: 30px 0;
    overflow: auto;
    max-height: 450px;
  }
  h4{
    .main-head{
      display: flex;
      justify-content: space-between;
      .title{
        color: #212326;
        font-size: 15px;
        @include font-weight-style(500, normal);
        margin-bottom: 5px;
        width: calc(100% - 60px);
        @include truncate();
      }
    }
    .year{
      width: 46px;
      height: 20px;
      font-size: 10px;
      padding: 3px 11px 4px;
      border-radius: 12.5px;
      color: $white;
      @include font-weight-style(500, normal);
      &.org{
        background-color: #f27212;
      }
      &.red{
        background-color: #ed1c24;
      }
      &.green{
        background-color: #33ae9a;
      }
      &.green-dark{
        background-color: #1bb934;
      }
      &.blue{
        background-color: #1eb7ff;
      }
      &.purple{
        background-color: #ca8eff;
      }
    }
  }
  .ant-list-item-meta-description{
    line-height: normal;
    position: relative;
    min-height: 32px;
    .para{
      color: #5e6267;
      font-size: 13px;
      line-height: 22px;
      @include font-weight-style(400, normal);
    }
    .date{
      display: flex;
      width: 100%;
      margin-top: 10px;
      span{
        font-size: 11px;
        font-style: italic;
        @include font-weight-style(400, normal);
        display: flex;
        position: relative;
        margin-right: 20px;
        &:first-child{
          &:after{
            content: '';
            display: block;
            width: 4px;
            height: 4px;
            position: absolute;
            right: -12px;
            top: 4px;
            border-radius: 50%;
            background-color: rgba(#5e6267, 0.5);
          }
        }
      }
    }
    .btns{
      display: flex;
      position: absolute;
      right: 0;
      top: -3px;
      z-index: 1;
      background-image: linear-gradient(to left, #fff 76%, rgba(255, 255, 255, 0));
      .link-btn{
        margin-right: 5px;
      }
    }
  }
}

.info-bnts{
  padding: 20px 0;
  .grey-sm-btn{
    height: 30px;
    & + .grey-sm-btn{
      margin-left: 10px;
    }
  }
}

.key-findings{
  p{
    line-height: 28px;
    font-size: 15px;
  }
}
.c-tbl-num-clr{
  color: #131416;
}

/* ==========================================================================
   TMA search
 ========================================================================== */
 .tma-search-box{
   .head-wrapper{
    width: 100%;
  }

   .tma-search{
    width: 100%;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    .floating-input{
      width: 480px;
    }
    .btns-box{
      display: flex;
    }
    .btn{
      font-size: 11px;
      &.grey-btn{
        color:$white;
        background-color: #5e6267;
        margin-left: 15px;
      }
    }
  }
 }

 /* ==========================================================================
  medical-history
 ========================================================================== */
 .medical-history{
   .medical-block{
     padding: 10px 0;
   & + .medical-block{
      border-top: dashed 1px rgba(#5e6267, 0.5);
    }
   }
   .block-area{
     margin: 30px 0;
     label{
      color:#5e6267;
      margin-bottom: 10px;
      font-size: 13px;
     }
     .data{
      font-size: 15px;
      color:#212326;
     }
     .grey-btn{
       height: 30px;
       font-size: 11px;
       min-width: 400px;
     }
   }
   .tag-box{
    display: flex;
    flex-wrap: wrap;
    .tag{
      font-size: $f-11;
      color:$white;
      width: auto;
      text-align: center;
      padding: 4px 10px;
      margin: 2px 2px;
      border-radius: 10px;
      background: #3f5bbb;
      border: 0;
      &.yellow{
        background: #f27212;
      }
      &.green{
        background: #1cb934;
      }
    }
  }
 }
 .list-cursor {
   cursor: pointer;
 }
