/* ==========================================================================
   checkbox and radio
   ========================================================================== */

   .ant-checkbox-inner{
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 18px;
    height: 18px;
    direction: ltr;
    background-color: #fff;
    border: 2px solid #7f8386;
    border-radius: 2px;
    border-collapse: separate;
    transition: all .3s;
   }

   .ant-checkbox-checked::after{
    border: 2px solid $primary-color;
   }
   .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $primary-color;
    border-color: $primary-color;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: $primary-color;
  }
  .ant-checkbox + span {
    padding-right: 15px;
    padding-left: 15px;
    font-size: 13px;
    @include font-weight-style(400, normal);
  }