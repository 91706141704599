/* You can add global styles to this file, and also import other style files */
// @import 'bootstrap.min.css';
@import '~antd/dist/antd.css';
@import '_variables.scss';
@import '_mixin.scss';
@import '_button.scss';
@import '_checkbox.scss';
@import '_form.scss';
@import '_modal.scss';
@import '_signup.scss';
@import '_main-dashboard.scss';
@import '_table.scss';

#root,
.App{
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

html,
body,
main {
  height: 100%;
  width: 100%;
  background: $base-color;
  font-size: 14px;
  font-family: 'Archivo', sans-serif;
  -webkit-text-size-adjust: 100%;
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-box-shadow: 0 0 0px 1000px $base-color inset !important;
  -moz-box-shadow: 0 0 0px 1000px $base-color inset !important;
  box-shadow: 0 0 0px 1000px $base-color inset !important;
  -webkit-text-fill-color: $black-head !important;
}

body{
  position: relative;
}

.main-container{
  width: 100%;
  height: 100%;
}

.mgt10{
  margin-top: 10px;
}
.mgt25{
  margin-top: 25px;
}
.mgt15{
  margin-top: 15px;
}
.mgt30{
  margin-top: 30px;
}
.mgt60{
  margin-top: 60px;
}
.mgb0{
  margin-bottom: 0 !important;
}
.mgb50{
  margin-bottom: 50px;
}
.pb0{
  padding-bottom: 0 !important;
}
.pt0{
  padding-top: 0 !important;
}
.mlr0{
  margin-left: 0;
  margin-right: 0;
}
.wid100{
  width: 100%;
}
.pdt0{
  padding-top: 0 !important;
}
textarea{
  min-height: 130px !important;
}
.mr20{
  margin-right: 20px;
}
p{
  color: $grey;
}
.mgt2{
  margin-top: 20px;
}