
@mixin font-shorthand($weight: $font-weight-base, $style:$font-style-base) {
	font-weight: $weight;
	font-style: $style;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

//-------main font Muli

@mixin font-weight-style($weight: $font-weight-base, $style: $font-style-base) {
	font-family: 'Archivo', sans-serif;
	@include font-shorthand($weight,$style);
}

@mixin font-weight-style-2($weight: $font-weight-base, $style: $font-style-base) {
	font-family: 'Frank Ruhl Libre', serif;
	@include font-shorthand($weight,$style);
}


@mixin box-shadow($top, $left, $blur, $color) {
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow: $top $left $blur $color;
    box-shadow: $top $left $blur $color;
}

@mixin shadow() {
	box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.02);
}

@mixin center() {
	display: flex;
	align-items: center;
	justify-content: center;
}

@mixin rounded($radius: 4px) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

@mixin round($radius: 4px) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	border-radius: $radius;
  }
	.left-auto{
		margin-left: auto;
	}

@mixin placeholder {
  ::-webkit-input-placeholder {@content}
  :-moz-placeholder           {@content}
  ::-moz-placeholder          {@content}
  :-ms-input-placeholder      {@content}
}

@mixin padding($top, $right, $bottom, $left) {
	padding-top: $top;
	padding-right: $right;
	padding-bottom: $bottom;
	padding-left: $left;
}
@mixin margin($top, $right, $bottom, $left) {
	margin-top: $top;
	margin-right: $right;
	margin-bottom: $bottom;
	margin-left: $left;
  }

@mixin big-head(){
	color:$black-head;
	font-size: $f-20;
	line-height: 24px;
	line-height: normal;
	text-transform: uppercase;
	@include font-weight-style(900, normal);
	span.sm-txt{
		color:#5e6267;
		margin-top: 5px;
		font-size: $f-15;
		display: block;
		text-transform: none;
		@include font-weight-style(400, normal);
	}
	span.sm-txt-b{
		font-size: $f-16;
		display: block;
		color:$black-sm;
		@include font-weight-style(600, normal);
	}
}

.big-head{
	@include big-head();
}

@mixin head(){
	color:$primary-color;
	font-size: $f-15;
	@include font-weight-style(400, normal);
	span{
		color:$grey;
		font-size: 13px;
		margin-top: 5px;
		display: block;
		@include font-weight-style(400, normal);
	}
}

.head{
	@include head();
}


@mixin wid100(){
	width: 100%;
	// float: left;
}

@mixin truncate(){
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

@mixin flex(){
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
}

@mixin white-box(){
	@include rounded();
	background: $base-color;
  box-shadow: 0 8px 18px 0 rgba(54, 136, 217, 0.04);
}

@mixin blue-box(){
  padding: 20px;
	color:$primary-color;
	background: $primary-lighter;
}

@mixin round-btn(){
	border-radius: 20px !important;
}

@mixin p(){
	font-size: $f-14;
	color:$grey-dark;
}

@mixin link-btn(){
    text-decoration: underline;
    text-transform: none;
    padding: 0 !important;
}

@mixin img() {
	height: 100%;
	width: 100%;
	object-fit: cover;
}

@mixin clearfix() {
	&::after {
	  display: block;
	  content: "";
	  clear: both;
	}
}

@mixin profile-pic(){
	width: 44px;
	height: 44px;
	@include round();
	overflow: hidden;
	background: url(../Assets/images/user-sm.png) no-repeat center;
	background-size: 20px;
	background-color: rgba(0, 0, 0, 0.20);
	img{
			width: 100%;
			height: 100%;
			object-fit: cover;
	}
}

@mixin logo(){
	width: 22px;
	height: 22px;
	border-radius: 2px;
	overflow: hidden;
	padding: 2px;
	background-color: $base-color;
	border: solid 1px #f5f5f5;
	display: flex;
	img{
			width: 100%;
			height: 100%;
	}
}


@mixin small-txt(){
	font-size: $f-13;
	color:rgba($black-dark, 0.5);
	@include font-weight-style(500, normal);
}

@mixin smaller-txt(){
	font-size: $f-13;
	color:$black;
}

@mixin from-contl-bd0(){
	border: 0;
	width: auto;
	background: transparent;
	padding: 0;
	display: inline-block;
	border-radius: 0;
}

@mixin dropdown-menu() {
	border:0;
	border-radius: 0;
	margin-top: 15px;
	box-shadow: 0 8px 18px 0 rgba(54, 136, 217, 0.13);
	.dropdown-item{
		cursor: pointer;
		padding: 11px 25px;
		&:hover{
			color:$drop-hover;
		}
	}
}

/* ==========================================================================
   flex mixing css for center alignment
	========================================================================== */
	@mixin flex-parent() {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}
	@mixin flex-center() {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	@mixin flex-hcenter() {
		display: flex;
		justify-content: center;
	}
	@mixin flex-vcenter() {
			display: flex;
			align-items: center;
	}
	@mixin flex-grow() {
		flex-grow: 1;
	}
	@mixin flex1() {
			flex: 1;
	}
	@mixin flex() {
			display: flex;
	}
//-------end input icons


/* ==========================================================================
   reuse classes
	========================================================================== */
.signup-txt{
	font-size: $f-12;
	color:$black-dark;
	position: relative;
	top: -10px;
	a{
		color:$black-dark;
		@include font-weight-style(700, normal);
	}
}

.green-txt{
	color:$primary-color !important;
}

.red-txt{
	color:$red-dark !important;
}

.round-user{
	width: 32px;
	height: 32px;
	border-radius: 50%;
	overflow: hidden;
	background-color: $grey-light;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	img{
			@include img();
	}
}

.input-type{
	input[type=file]{
		opacity: 0;
		width: 100%;
		height: 100%;
		font-size: 0px;
		left: 0;
		top: 0px;
		cursor: pointer;
		position: absolute;
		color: transparent;
	}
}

.user-profile-inline{
	display: flex;
	.logo-company{
		@include logo();
	}
	.profile-pic{
		border-radius: 0;
		@include profile-pic();
		background-color: $grey-light;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-shrink: 0;
	}
	.profile-content{
		display: flex;
		justify-content: center;
		flex-direction: column;
		line-height: normal;
		font-size: 15px;
		margin-left: 10px;
		color:$black-head;
		@include font-weight-style(700, normal);
		span.detail{
			color:$form-placeholder;
			font-size: $f-12;
			display: block;
			@include font-weight-style(600, normal);
		}
		span.match-case{
			font-size: $f-12;
			color:$black-sm;
			@include font-weight-style(400, normal);
		}
		span.user-name-sm{
			font-size: $f-12;
			@include font-weight-style(600, normal);
		}
	}
}

.user-type{
	margin: 40px 0 7px 0;
	font-size: 19px;
	@include font-weight-style-2(600, normal);
	span{
		font-size: 13px;
		@include font-weight-style-2(400, normal);
	}
}

.tag-box{
  display: flex;
  flex-wrap: wrap;
  .tag{
    font-size: $f-11;
    color:$black-sm;
    width: auto;
    text-align: center;
		padding: 2px 5px;
		margin: 2px 2px;
    border-radius: 10px;
    border:1px solid $black-sm;
  }
}


/* ==========================================================================
   Loan section
	========================================================================== */
	h3.loan-amount{
    font-size: 57px;
    color:$primary-color;
    @include font-weight-style-2(900, normal);
  }
  ul.loan-listing{
    margin: 15px 0 30px 0;
		padding-inline-start: 0;
    li{
      border:0;
      font-size: $f-12;
      padding: 5px 0 0 0;
      color:$black-sm;
      text-transform: uppercase;
      @include font-weight-style(600, normal);
      .loan-per{
        margin-right: 5px;
        font-size: 23px;
        @include font-weight-style-2(900, normal);
      }
		}
		&.sm-list{
			li{
				font-size: $f-12;
				text-transform: none;
				@include font-weight-style(400, normal);
				.loan-per{
					font-size: $f-12;
					@include font-weight-style(700, normal);
				}
				&.sm-head{
					color:$black-sm;
					font-size: $f-11;
					text-transform: uppercase;
					@include font-weight-style(700, normal);
				}
			}
		}
	}

	.black-strip{
    display: flex;
    flex-wrap: wrap;
		.loan-black-strip{
			color:$base-color;
			font-size: $f-12;
			min-width: 187px;
			border-radius: 2px;
			padding: 5px 10px;
			margin-top: 5px;
			background-color: #252525;
			@include font-weight-style(700, normal);
		}
	}

	.close-icon{
		&.close{
			opacity: 1 !important;
			outline: 0;
			.icon-cancel-grey{
				font-size: 12px;
				&.black{
					&:before{
						color:$black;
					}
				}
			}
		}
	}

/* ==========================================================================
   not in use classes
	========================================================================== */

.page-header-sub{
	margin-bottom: 50px;
	h2{
			@include head();
			font-size:$f-16;
			letter-spacing: 0.6px;
			@include font-weight-style(600, normal);
			@media only screen and (max-width: 767px) {
				text-transform: none;
				letter-spacing: 0;
			}
		}
	.btn{
			color:#3688d9;
			font-size: $f-14;
			padding: 0;
			margin:0 0 0 5px;
			text-transform: none;
	}
}

.page-header-sub{
	&.center{
		width: 100%;
		h2{
			text-align: center;
		}
	}
}

.empty-box{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	min-height: 296px;
	padding: 15px;
	background: $base-color;
	h4{
		font-size: 23px;
		color:$black-head;
		text-align: center;
		margin: 0 0 32px 0;
		@include font-weight-style-2(700, normal);
		@media only screen and (max-width: 767px) {
			font-size: $f-16;
		}
	}
	span.smaller{
		color:$black-head;
		font-size: $f-13;
		margin-top: 22px;
		@include font-weight-style(600, normal);
	}
	.big-head{
		@include big-head();
		@media only screen and (max-width: 767px) {
			font-size: 25px;
			.sm-txt-b{
				margin-top: 5px;
			}
		}
	}
}

.profile-inline{
	&.round{
		.profile-pic{
			border-radius: 50%;
		}
	}
}

.circle-close{
	width: 24px;
  height: 24px;
	border-radius: 50%;
	padding: 0;
	font-size: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: $base-color;
	background: #828f9a;
	i{
		left: 1px;
    top: 0px;
		color:$base-color;
		position: relative;
	}
}

/* ==========================================================================
   dropdowns
	========================================================================== */
	.setting-dropdown{
		.anticon-down{
			display: none;
		}
		.btn-secondary{
			padding: 0;
			background-color: transparent !important;
			border:0;
			font-size: 0;
			&:hover, &:active, &:focus{
				outline: 0;
				box-shadow:none !important;
				background-color: transparent !important;
				border-color: transparent !important;
			}
		}
		.icon-setting{
			color:$black-dark;
			font-size: 30px;
		}
		.dropdown-toggle{
			&::after{
				border:0;
			}
		}
	}


.custom-dropdown {
	padding: 0;
	background-color: rgba(#5e6267, 0.04);
	border-radius: 4px;
	margin-right: 10px;
	.grey-btn{
		color:#5e6267;
		width: 130px;
		font-size: $f-13;
		text-transform: none;
		display: flex;
		align-items: center;
		height: 40px;
		justify-content: space-between;
		@include font-weight-style(400, normal);
	}
	.dropdown-item {
		color: $primary-color;
		font-size: $f-13;
		padding: 8px 20px;
		cursor: pointer;
		outline: 0;
		position: relative;
		border: 0;
		-webkit-appearance: none;
		@include font-weight-style(400, normal);
		&:hover {
			color: darken($primary-color, 25%);
			background: $base-color;
		}
		&.red{
			color:$red-dark;
			&:hover{
				color: darken($red-dark, 25%);
			}
		}
	}
	.dropdown-item.active {
		color: $drop-hover;
		background: $base-color;
	}
}

.ant-dropdown{
	width: 200px;
	padding: 12px 0;
	li.ant-dropdown-menu-item{
		a{
			color: #212326;
			span.data{
				width: calc(100% - 25px);
				padding-left: 10px;
			}
			.img-box{
				width: 25px;
				img{
					width: 20px;
				}
			}
			&.red{
				color:$red-dark;
			}
		}
	}
}

.delete-txt{
	color:$red-light !important;
}

.back{
  width: 40px;
  height: 40px;
  margin: 0 20px 0 0;
  padding: 10px;
  border-radius: 4px;
  line-height: normal;
	border: 0;
  background-color: rgba(#5e6267,  0.06) !important;
}
.btn-tick{
	width: 80px;
  height: 80px;
  margin: 10px 175px 40px 160px;
  padding: 15px;
	border-radius: 50%;
  background-image: linear-gradient(45deg, #212326, #131416);
}

/* ==========================================================================
   checkbox
 ========================================================================== */
 .ant-checkbox-inner{
	 background-color: transparent;
	 border: 0;
 }
 /* ==========================================================================
   notification
 ========================================================================== */
 .ant-notification{
	 .ant-notification-notice{
		 margin-right: auto;
		 width: 380px;
		 padding: 13px 10px 12px 20px;
		 border-radius: 4px;
		 display: flex;
     align-items: center;
		 min-height: 50px;
	 }
	 .ant-notification-notice-close{
		top: 18px;
    right: 15px;
		.ant-notification-close-x{
			.ant-notification-close-icon{
				display: none;
			}
			&::after{
				content: '';
				display: block;
				background: url(../Assets/images/svg/close-icon.svg) no-repeat;
				width: 15px;
				height: 15px;
			}
		}
	 }
	 .ant-notification-notice-description{
		 font-size: 14px;
		 margin-left: 35px;
		 padding-right: 15px;
		 line-height: 15px;
		 @include font-weight-style(400, normal);
	 }
	 .ant-notification-notice-message{
		 display: none;
	 }
	 .ant-notification-notice-icon{
		font-size: 21px;
	 }
	 .ant-notification-notice-error{
		background: $red-dark;
		color:$white;
	 }
	 .ant-notification-notice-icon-error{
		 svg{
			 display: none;
		 }
		 &::before{
			content: '';
			display: block;
			background: url(../Assets/images/svg/alert-icon.svg) no-repeat top center;
			width: 16px;
			height: 16px;
		}
	 }
	 .ant-notification-notice-success{
		 background: $green;
		 color:$white;
	 }
	 .ant-notification-notice-icon-success{
		svg{
			display: none;
		}
		&::before{
		 content: '';
		 display: block;
		 background: url(../Assets/images/svg/success-icon.svg) no-repeat top center;
		 width: 16px;
		 height: 18px;
	 }
	}
 }

  /* ==========================================================================
   laoder
 ========================================================================== */
 .loader-main{
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  margin: auto;
  right: 0;
  bottom: 0;
  background-color: rgba(#000, 0.7);
  transition: all 0.6s;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
 }

 .ant-scrolling-effect{
	.loader-main{
		background-color: rgba(#000, 0.5);
		z-index: 1001;
	}
 }

//  .ant-spin .ant-spin-spinning{
// 	 display: flex;
// 	 align-items: center;
// 	 justify-content: center;
// 	 height: 100%;
// 	 width: 100%;
//  }
//  .ant-spin-dot{
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// 	height: 100%;
// 	width: 100%;
//  }


/* ==========================================================================
   laoder
 ========================================================================== */
 .ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-open .ant-select-selector, .ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-focused .ant-select-selector{
	 box-shadow: none;
 }
 .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector{
	min-height: 55px;
	max-height: 250px;
  overflow: auto;
 }

 .multi-select-floating{
	 .ant-form-item-control-input{
		// min-height: 55px;
	 }
 }

 .ant-select{
	 &.multi-select{
		.ant-select-selection-item{
			font-size: 15px;
			color: #212326;
			@include font-weight-style(500, normal);
		}
		.ant-select-selection-item-content{
			text-transform: capitalize;
		}
	 }
	}

	.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
	 color: $primary-color;
	 display: flex;
	 align-items: center;
	 position: absolute;
	 left: 0;
	 top: 0;
	 bottom: 0;
	 padding: 10px;
	 height: 50px;
 }

 .ant-select-item{
	 padding: 15px 12px;
	 min-height: 65px;
	 border-bottom: dashed 1px rgba(#5e6267, 0.5);
	}

	.ant-select-item-option-selected:not(.ant-select-item-option-disabled){
		background-color: transparent;
		&:hover{
			background-color: #f5f5f5;
		}
	}

 .multi-select-option{
	 display: flex;
	 align-items: center;
	 .profile-pic{
		width: 35px;
		height: 35px;
		margin-left: 20px;
		border-radius: 4px;
		overflow: hidden;
		img{
			width: 100%;
			height: 100%;
		}
	 }
	 .profile-content{
		width: calc(100% - 55px);
		padding-left: 10px;
		display: flex;
		flex-direction: column;
		span.name{
			font-size: 12px;
			color: #212326;
			text-transform: capitalize;
			@include font-weight-style(400, normal);
		}
		span.email{
			font-size: 13px;
			color: #5e6267;
			display: block;
			@include font-weight-style(400, normal);
		}
	 }
	 .cases{
		 color: #1ed79e;
		 font-size: 11px;
		 @include font-weight-style(400, normal);
		 position: absolute;
		 right: 10px;
		 height: 100%;
		 display: flex;
		 align-items: center;
	 }
	 .cases-inActive{
		color:red;
		font-size: 11px;
		@include font-weight-style(400, normal);
		position: absolute;
		right: 10px;
		height: 100%;
		display: flex;
		align-items: center;
	}
 }

//  .ant-list-split .ant-list-item{
// 	cursor: pointer;
// }